import Link from 'next/link';
import DividerBlock from '../StaticBlocks/DividerBlock';
import Heading2Block from '../StaticBlocks/Heading2Block';
import Paragraph from '../StaticBlocks/Paragraph';
import SingleLink from '../StaticBlocks/SingleLink';
import Footer from './Footer';
import ExternalLinks from './sections/ExternalLinks';
import FooterCategories from './sections/FooterCategories';
import SocialMedia from './sections/SocialMedia';
import SitemapLink from './sections/Sitemap';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { footerDefaultColumnStyles } from '../../constant/default_styles/FooterDefaultStyles';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { latest_editor_version } from '../../constant/Version';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import WideSearchBox from '../SearchBox/WideSearchBox';
import HeadingBlock from '../StaticBlocks/HeadingBlock';
import DescriptionBlock from '../StaticBlocks/DescriptionBlock ';
import ImageBlock from '../StaticBlocks/ImageBlock';
import ImageDestinationBlock from '../StaticBlocks/ImageDestinationBlock';
import FeaturedCard2 from '../home/FeaturedCrad/FeaturedCard2';
import TitleDescriptionButton from '../StaticBlocks/TitleDescriptionButton';
import VideoBlock from '../StaticBlocks/VideoBlock';
import QuoteBlock from '../../themes/blank/QuoteBlock';
import TrustedBlock from '../../themes/blank/TrustedBlock';
import CardBlock from '../StaticBlocks/CardBlock';
import CardBlockImageFirst from '../StaticBlocks/CardBlockImageFirst';
import CarouselCardBlock from '../StaticBlocks/CarouselCardBlock';
import AboutUs from '../StaticBlocks/AboutUs';
import LeftImageFeaturedCard from '../home/FeaturedCrad/LeftImageFeaturedCard';
import CustomCta from '../StaticBlocks/CustomCta';
import EmptyBlock from '../StaticBlocks/EmptyBlock';
import { common_blocks_cases } from '../../themes/blank/BlockDecide/CommonBlockCase';
import AnimatedTextBlock from '../StaticBlocks/AnimatedTextBlock';

const DynamicFooter = () => {
  const { width } = useScreenSize();
  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const router = useRouter();

  const DynamicFooterStyle =
    TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_footer ?? undefined;
  // component decide
  const component_decide = ({
    block,
    block_code,
    isStatic,
    data,
    styles,
    isEnabled = true,
    items,
  }) => {
    const section = {
      block,
      block_code,
      isStatic,
      data,
      styles,
      isEnabled,
      items,
    };
    switch (block) {
      case 'logo':
        return (
          isEnabled && (
            <Link href="/" passHref={true}>
              <a className="  flex items-center   relative cursor-pointer  ">
                <img
                  src={logo}
                  style={{
                    maxHeight: styles?.logo_styles?.max_height ?? '44px',
                    width: styles?.logo_styles?.width,
                    objectFit: styles?.logo_styles?.object_fit ?? 'contain',
                  }}
                  alt="logo"
                />
              </a>
            </Link>
          )
        );

      case 'footer_category':
        return isEnabled && <FooterCategories data={data} styles={styles} />;
      case 'social_links':
        return isEnabled && <SocialMedia data={data} styles={styles} />;
      case 'external_links':
        return isEnabled && <ExternalLinks data={data} styles={styles} />;
      case 'single_link':
        return isEnabled && <SingleLink data={data} styles={styles} />;
      case 'paragraph':
        return isEnabled && <Paragraph data={data} styles={styles} />;
      case 'sitemap_links':
        return isEnabled && <SitemapLink data={data} styles={styles} />;

      case 'divider_block':
        return <DividerBlock styles={styles} data={data} />;

      case 'heading2_block':
        return <Heading2Block data={data} styles={styles} />;
      case 'animated_paragraph':
        return <AnimatedTextBlock data={data} styles={styles} />;

      //

      case 'searchblock':
        return <WideSearchBox data={data} styles={styles} />;

      case 'heading_title':
        return data?.enabled && <HeadingBlock data={data} styles={styles} />;

      case 'description_block':
        return (
          data?.enabled && <DescriptionBlock data={data} styles={styles} />
        );
      case 'image_block':
        return data?.enabled && <ImageBlock data={data} styles={styles} />;
      case 'image_with_destination_block':
        return (
          data?.enabled && <ImageDestinationBlock data={data} styles={styles} />
        );
      case 'featured_card':
        return data?.enabled && <FeaturedCard2 data={data} styles={styles} />;
      case 'title_desc_button':
        return (
          data?.enabled && (
            <TitleDescriptionButton data={data} styles={styles} />
          )
        );

      case 'video_featured':
        return data?.enabled && <VideoBlock data={data} styles={styles} />;
      case 'quote_block':
        return data?.enabled && <QuoteBlock data={data} styles={styles} />;
      case 'trusted_block':
        return data?.enabled && <TrustedBlock data={data} styles={styles} />;

      case 'card_block':
        return <CardBlock data={data} styles={styles} />;
      case 'card_block_top_image':
        return <CardBlockImageFirst data={data} styles={styles} />;
      case 'carousel_card_block':
        return <CarouselCardBlock data={data} styles={styles} />;
      case 'about_us':
        return <AboutUs data={data} styles={styles} />;
      case 'left_image_featured_card':
        return <LeftImageFeaturedCard data={data} styles={styles} />;

      case 'cta':
        return <CustomCta data={data} styles={styles} />;

      case 'empty_block':
        return <EmptyBlock data={data} styles={styles} />;

      case 'animated_paragraph':
        return <AnimatedTextBlock data={data} styles={styles} />;
      default:
        return common_blocks_cases({ section });
    }
  };

  return (
    <>
      {/* New */}
      {DynamicFooterStyle && (
        <div
          className={'bottom-0'}
          id="footer"
          style={get_dynamic_styles(
            responsive_design_styles({
              parent_group: DynamicFooterStyle?.styles,
              child_group_name: 'main_styles',
              width,
            })
          )}
        >
          <div
            className={` relative `}
            style={get_dynamic_styles(
              responsive_design_styles({
                parent_group: DynamicFooterStyle?.styles,
                child_group_name: 'box_styles',
                width,
              })
            )}
          >
            {/* Rows */}

            {DynamicFooterStyle?.schema?.map((row) => {
              const {
                columns,
                current_editor_version = '',
                ...column_styles
              } = row || {};
              const row_styles = responsive_row_styles({
                parent_group: row,
                width,
              });
              return (
                ((width < 768 && row_styles?.isShowInMobile !== false) ||
                  (width > 768 && row_styles?.isShowInWeb !== false)) && (
                  <div
                    style={
                      current_editor_version == latest_editor_version
                        ? {
                            display: row_styles?.display,
                            gridTemplateColumns: dynamic_text_convert({
                              main_text: row_styles?.columns_box_percentage,
                              check_by: '-1%',
                              value: '',
                            }),
                            ...get_dynamic_styles(row_styles),
                          }
                        : {
                            display:
                              row_styles?.isShowInMobile === false
                                ? 'none'
                                : 'grid',
                            gap: row_styles?.gap_between_columns ?? '24px',
                            gridTemplateColumns:
                              width > 768
                                ? row_styles?.columns_box_percentage
                                  ? row_styles?.columns_box_percentage
                                  : `repeat(${
                                      row.columns?.length ?? 1
                                    }, minmax(0, 1fr))`
                                : row?.['768_row_styles']
                                ? row_styles?.columns_box_percentage
                                : '100%',
                            ...get_dynamic_styles(row_styles),
                          }
                    }
                  >
                    {/* Columns */}
                    {columns.map((column, index) => {
                      const current_column_style = responsive_column_styles({
                        parent_group: column_styles,
                        index,
                        width,
                      });

                      return (
                        <div
                          style={{
                            display: current_column_style?.display
                              ? current_column_style?.display
                              : 'flex',
                            ...get_dynamic_styles_with_default_value(
                              current_column_style,
                              footerDefaultColumnStyles
                            ),
                          }}
                        >
                          {column?.map((item) => {
                            return (
                              component_decide({ ...item }) &&
                              component_decide({ ...item })
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default DynamicFooter;
